<template>
  <div class="quickbuilder-step4 flex" style="height:100%">
    <!-- left -->
    <div class="background left">
      <div class="size-14 font-text2">주문방식 외에도 플러그인을 추가할 수 있습니다.</div>
      <div class="size-24 margin-bottom-24">내 서비스에 <b>플러그인</b>을 추가해보세요!</div>

      <div class="position-relative">
        <!-- 탭 제목 -->
        <div class="flex-align">
          <div class="unselect tab size-14" v-for="(tab, tab_idx) in tabArr"
               :class="{ 'active': selectedTab && selectedTab.value === tab.value }"
               :key="`tab-${tab_idx}`"
               @click="clickTab(tab)">{{ tab.label }}
            <div class="line"></div>
          </div>
        </div>
        <!-- 탭 컨텐츠 -->
        <div class="tab-content" v-if="tabContent">
          <template v-for="(content,idx) in tabContent">
            <div :key="'tab-content-'+idx" class="grid-content">
              <div class="margin-bottom-12">{{ content.label }}</div>
              <div class="grid-container" v-if="content.products.length>0">
                <div v-for="(child, child_idx) in content.products" :key="`tab-content-${child_idx}`"
                     class="grid-item unselect"
                     :class="{'clicked':selectedItem && selectedItem.id === child.value.id, 'selected': isSelected(child.value)}"
                     @click="clickItem(child)">{{ child.label }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="right" v-if="selectedItem.name !== ''">
      <div style="background-color:white;padding:24px 54px">
        <div class="text-center">
          <img style="width:100%" :src="selectedItem.img"/>
        </div>

        <div class="flex-between" style="min-width:200px">
          <div>
            <div class="weight-600 size-18">{{ selectedItem.name }}</div>
            <div class="margin-top-12">
              <div class="discount-price size-13">{{ selectedItem.price.org_price|currency }}</div>
              <div class="weight-600 font-primary">{{ selectedItem.price.price | currency }}</div>
            </div>
          </div>
          <button class="unselect button" @click="clickSelect"
                  :class="{'is-primary-2': !isSelected(selectedItem), 'is-primary': isSelected(selectedItem)}"
          >{{ isSelected(selectedItem) ? '선택됨' : '선택하기' }}</button>
        </div>

        <div class="font-text2 margin-top-20" v-html="selectedItem.info"></div>
      </div>
      <div class="position-relative" style="padding:0 54px;background-color:white">
        <div class="divide-dashed"></div>
        <div class="rotate-rectangle"></div>
      </div>
      <!-- 장바구니 영역 -->
      <div style="background-color:white;padding:24px 54px">
        <div style="margin-bottom:12px">장바구니</div>
        <div class="flex-between" v-for="(item,idx) in cart" :key="'cart-'+idx" style="margin-bottom:4px">
          <div class="font-primary">{{item.name }}</div>
          <div class="flex-align">
            <div class="margin-right-12 font-text2">
              <span v-if="item.price.price === 0">무료</span><span v-else>{{ item.price.price|currency }}</span>
            </div>
            <i class="btn-delete unselect material-icons font-border2" @click="clickDelete(item)">close</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SuperBuilderStep4",
    created() {
      this.getData();
    },
    props: {
      service: {
        type: Object
      }
    },
    data() {
      return {
        tabArr: [],
        selectedTab: undefined,
        selectedItem: {
          name: '',
          id:0
        }
      }
    },
    computed: {
      tabContent() {
        let content = undefined;
        if(this.selectedTab) {
          if(this.selectedTab.value === 0) {
            content = this.tabArr.slice(1);
          } else {
            content = [this.selectedTab];
          }
        }
        return content;
      },
      cart() {
        return this.service.conversions.concat(this.service.plugins);
      },
    },
    methods: {
      clickItem(item) {
        this.selectedItem = item.value;
      },
      isSelected(plugin) {
        return this.service.plugins.findIndex(item => { return item.id === plugin.id }) > -1;
      },
      clickSelect() {
        let index = this.service.plugins.findIndex(item => { return item.id === this.selectedItem.id });
        if(index === -1) {
          this.service.plugins.push(this.selectedItem);
        } else {
          this.service.plugins.remove(index);
        }
      },
      getData() {
        this.$axios.get('public/category?depth=2').then(async res=>{
          // 플러그인 카테고리(id=7)에 해당하는 상품목록
          let products = await this.$axios.get('user/0/mapping/product?category=7&fields=name,img,info,price,id,category,params&page_length=100&ordering=id');

          products.data.data.forEach(item=>{
            item.price.org_price = item.price.price;
            item.price.price = item.price.price - item.price.discount_price;
          });
          let plugin = res.data.filter(item => { return item.name === '플러그인' })[0];
          let child = plugin.child.map(item => {
            return {
              label: item.name,
              value: item.id,
              products: this.setProducts(products.data.data.filter(prod => { return prod.category.indexOf(item.name) > -1 }))
            }
          });
          this.tabArr = child.filter(item => { return item.products.length > 0 });

          // 카테고리에 전체보기 추가
          this.tabArr.splice(0,0, {
            label: '전체보기',
            value: 0
          });
          this.selectedTab = this.tabArr[0];
          this.selectedItem = this.tabContent[0].products[0].value;
        });
      },
      setProducts(data) {
        data = data.map(data => {
          return {
            label: data.name,
            value: data
          }
        });
        // 강제로 빈 갯수 만큼 채우기
        let remain = 4 - Math.round(data.length % 4);
        if (remain < 4) {
          for (let i = 0; i < remain; i++) {
            data.push({
              label: '',
              value: ''
            })
          }
        }
        return data;
      },
      clickTab(tab) {
        this.selectedTab = tab;
      },
      clickDelete(item) {
        let name = item.category === '주문전환' ? 'conversions' : 'plugins';
        let index = this.service[name].findIndex(s => { return s.id === item.id });
        this.service[name].remove(index);
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .left
    width 65%
    height 100%
    padding-left 15%
    padding-right 2%
    padding-top 30px

  .right
    width 35%
    background-color white

  .package-box-st
    text-align center
    padding 12px
    border 1px solid $border2
    border-radius 4px
    margin 6px

  .tab
    padding 12px 16px

  .tab.active
    position relative
    font-weight bold
    box-shadow 0 -4px 6px 0 rgba(0, 0, 0, 0.1)
    border-top-left-radius $radius
    border-top-right-radius $radius

    .line
      height 14px
      width 100%
      position absolute
      bottom -5px
      left 0
      background-color $background

  .grid-container
    display flex
    flex-wrap wrap
    border-radius $radius
    border 1px solid $border2
    overflow hidden
    margin-bottom 20px
  .grid-content:last-child
    .grid-container
      margin-bottom 0


  .grid-item
    border-right 1px solid $border2
    border-top 1px solid $border2
    width 25%
    padding 20px 0
    text-align center
  .clicked
    background-color #ffffff
  .selected
    color $primary

  .grid-item:nth-child(4n)
    border-right 0

  .grid-item:nth-child(-n+4)
    border-top 0

  .divide-dashed
    border-top 2px dashed $border2
    padding 0 54px

  .rotate-rectangle
    width: 15px;
    height: 15px;
    background-color: $background;
    transform: rotate(45deg);
    position: absolute;
    left: -6px;
    top: -6px;

  .tab-content
    padding 16px
    border-radius $radius
    box-shadow 0 2px 12px #d4d4d4
  .btn-delete:hover
    color $primary

  .discount-price
    text-decoration line-through
    color #797979
</style>
